import PropTypes from 'prop-types';
import React, { Children } from 'react';

/**
 * returns JSX structure for a11y breadcrumbs based on https://www.w3.org/TR/wai-aria-practices-1.1/examples/breadcrumb/index.html
 * @returns {JSX.Element}
 */
export const Breadcrumbs = ({ label = `Brødsmulesti`, className, children, ...rest }) => {
  const classes = [
    `breadcrumbs`,
    className
  ].join(` `);

  return !!children &&
    <nav aria-label={label} className={classes} {...rest}>
      <ol>
        {Children.map(children, (child, i) => {
          if (
            child
            && (
              String(child)
              && String(child).trim()
            )
          )
            return <li key={i}>{child}</li>;
        })}
      </ol>
    </nav>;
};

Breadcrumbs.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};


export default Breadcrumbs;
