import PropTypes from 'prop-types';
import { useRef } from 'react';

/**
 * @param value
 * @param title
 * @param placeholder
 * @param isInvalid
 * @param onChange
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const Input = ({
  value = undefined,
  title,
  placeholder,
  isInvalid = false,
  onChange = undefined,
  ...rest
}) => {
  const refInput = useRef();

  /**
   * onChange decorator
   * Passes input value and event
   * @param event
   */
  const handleChange = (event) => {
    if (!onChange) {
      return;
    }

    onChange(refInput?.current.value, event);
  };

  return (
    <input
      ref={refInput}
      value={value}
      title={title || placeholder}
      placeholder={placeholder}
      aria-invalid={isInvalid}
      onChange={handleChange}
      {...rest}
    />
  );
};

Input.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
};


export default Input;
