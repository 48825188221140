import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

/**
 *
 * @param label
 * @param id
 * @param name
 * @param value
 * @param className
 * @param onChange
 * @param onBlur
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */

export function RadioButton({
  label,
  children,
  id,
  name,
  value,
  className,
  onChange = undefined,
  onBlur = undefined,
  ...rest
}) {
  const refInput = useRef(null);
  const refLabel = useRef(null);

  const classes = ['radio__input', className].join(` `);

  /**
   * add is-selected class to wrapper if input checked
   * @param isChecked
   */
  const handleChecked = (isChecked) => {
    if (isChecked) {
      refLabel?.current.classList.add('is-selected');
      return;
    }
    refLabel?.current.classList.remove('is-selected');
  };

  /**
   * onChange decorator
   * @param event
   */
  const handleChange = (event) => {
    handleChecked(event.target.checked);
    onChange && onChange(event);
  };

  /**
   * onBlur decorator
   * @param event
   */
  const handleBlur = (event) => {
    handleChecked(!event.target.checked);
    onBlur && onBlur(event);
  };

  useEffect(() => {
    const isChecked = refInput?.current.checked;
    handleChecked(isChecked);
  });

  return (
    <label ref={refLabel} className={'radio'}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        className={classes}
        ref={refInput}
        onChange={handleChange}
        onBlur={handleBlur}
        {...rest}
      />

      <span className={'radio-text'}>{children || label}</span>
    </label>
  );
}

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};



export default RadioButton;
