import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Chevron from '../../assets/chevron-down.svg';

/**
 *
 * @param id
 * @param title
 * @param open
 * @param className
 * @param children
 * @param onClick
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export function Accordion({
  id = undefined,
  title = undefined,
  open = undefined,
  className = undefined,
  children = undefined,
  onClick = undefined,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(open || false);
  const [setHeight, setHeightState] = useState(0);

  const contentRef = useRef(null);

  const classes = [
    `accordion`,
    isOpen ? `accordion--open` : ``,
    className,
  ].join(` `);

  /**
   * handles accordion expanding and adds maxHeight value required for CSS animations
   * @param event
   */
  function handleToggle(event) {
    event.preventDefault();
    setIsOpen(!isOpen);
    setHeightState(!isOpen ? contentRef?.current?.scrollHeight : 0);
    onClick && onClick(event);
  }

  return (
    <div className={classes} {...rest}>
      <button
        onClick={handleToggle}
        className={'accordion__header'}
        aria-label={title}
        aria-controls={id}
        aria-expanded={isOpen}
      >
        <i className={'accordion__arrow'} aria-hidden={true}>
          <Chevron />
        </i>
        <span className={'accordion__title'}>{title}</span>
      </button>
      <div
        id={id}
        ref={contentRef}
        style={{ maxHeight: `${setHeight}px` }}
        className={'accordion__body'}
      >
        <div className={'accordion__content'}>{children}</div>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
};


export default Accordion;
