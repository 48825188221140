import React from "react";
import styles from './edu-card.module.scss';
import PropTypes from 'prop-types';
import {isValidString} from "../../helpers/functions";
import Chevron from './chevron.svg';

export function EduCard({colorName = undefined, colorValue = undefined, href = undefined, className, children = undefined, ...rest}) {
  const hasHref = isValidString(href);
  const hasColorName = isValidString(colorName);
  const hasColorValue = isValidString(colorValue);
  const borderColor = hasColorValue && !hasColorName ? {borderColor: colorValue} : null;
  const Tag = hasHref ? 'a' : 'div';

  const classes = [
    `edu-card`,
    hasColorName ? `edu-card--${colorName}` : ``,
    className,
  ].join(' ');

  return (
    <Tag href={href} className={classes} style={borderColor} {...rest}>
      <div className={styles['edu-card__content']}>
        {children}
      </div>

      {hasHref &&
      <i className={styles['edu-card__arrow']}>
        <Chevron/>
      </i>
      }
    </Tag>
  );
}

EduCard.propTypes = {
  children: PropTypes.any,
  colorName: PropTypes.oneOf([
    undefined,
    null,
    'vgo',
    'fhs',
    'fs',
    'ek',
    'uh',
  ]),
  colorValue: PropTypes.string,
  href: PropTypes.string,
};



export default EduCard;
