import React from 'react';
import PropTypes from 'prop-types';

export default function More({ title = 'Vil du vite mer?', children }) {
  return (
    <div className="article__show-more">
      <h2>{title}</h2>
      <nav>{children}</nav>
    </div>
  );
}

More.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
