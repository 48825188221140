import React from 'react';
import PropTypes from 'prop-types';

/**
 * returns formatted counter
 * @param counter
 * @returns {JSX.Element}
 */
const getValidCounter = counter => {
  if (counter < 100) {
    return <span>{counter}</span>
  }
  return <span>99<small>+</small></span>
};

/**
 *
 * @param icon
 * @param isActive
 * @param counter
 * @param counterLabel
 * @param className
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */

export function Marker({icon = undefined, isActive = false, counter = undefined, counterLabel = undefined, className, children = undefined, ...rest}) {
  const hasIcon = React.isValidElement(icon);
  const hasCounter = !isNaN(counter) && counter >= 0;
  const classes = [
    'marker',
    hasIcon ? 'marker--icon' : ``,
    isActive ? 'marker--active' : ``,
    hasCounter ? 'marker--counter' : ``,
    className,
  ].join(` `);

  return (
    <button className={classes} {...rest} aria-pressed={isActive}>
      {hasIcon && <i className={'marker__icon'}>{icon}</i>}
      <span>{children}</span>
      {hasCounter && <div title={counterLabel} className={'marker__counter'}>{getValidCounter(counter)}</div>}
    </button>
  );
}

Marker.propTypes = {
  icon: PropTypes.element,
  isActive: PropTypes.bool,
  counter: PropTypes.number,
  counterLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
};



export default Marker;
