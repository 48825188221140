import React from "react";
import PropTypes from 'prop-types';
import Arrow from '../../assets/infobox-arrow.svg';

export function InfoBox({content, className, ...rest}) {
  const classes = [
    'info-box',
    className,
  ].join(` `);

  return (
    <div
      className={classes}
      {...rest}>
      <div
        dangerouslySetInnerHTML={{__html: content}}
        className={'info-box__content'}
      />
      <Arrow className={'info-box__arrow'} aria-hidden={true}/>
    </div>
  );
}

InfoBox.propTypes = {
  content: PropTypes.string.isRequired
};


export default InfoBox;
