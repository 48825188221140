import React from 'react';
import { Button } from '../button/button';
import { useState } from 'react';
import PropTypes from 'prop-types';

const Plus = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 15 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M7.58105 1V13"
        stroke="#333333"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 7.08096L1.5 6.99988"
        stroke="#333333"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Minus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 2"
  >
    <g>
      <path
        d="M16.4498 0.94375L1.5498 0.84375"
        stroke="#333333"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default function Summary({ title = 'Oppsummering', summary, children }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="article__summary" aria-expanded={open}>
      <div className="summary__content">
        <h2>{title}</h2>
        {summary ? (
          <div
            className="summary__body"
            dangerouslySetInnerHTML={{ __html: summary }}
          />
        ) : (
          children
        )}
      </div>
      <Button onClick={() => setOpen((prev) => !prev)}>
        {open ? <Minus /> : <Plus />} Vis {open ? 'mindre' : 'mer'}
      </Button>
    </div>
  );
}

Summary.propTypes = {
  title: PropTypes.string,
  summary: PropTypes.string,
  children: PropTypes.node,
};
