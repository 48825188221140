import React from 'react';
import { Button } from '../button/button';
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import Summary from './Summary';
import More from './More';
import PropTypes from 'prop-types';

export function Article({
  breadcrumbs,
  title,
  intro,
  image,
  summary,
  content,
  showMore,
  lastUpdated,
  onFavoriteClick,
  children,
}) {
  const headerContentClasses = [
    'article__content',
    image && 'article__content--image',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <article className="article">
      <section className="article__header">
        <div className={headerContentClasses}>
          {!!breadcrumbs && breadcrumbs.length > 0 && (
            <Breadcrumbs>
              {breadcrumbs.map((breadcrumb) => {
                const Tag = breadcrumb.linker ?? 'a';
                return breadcrumb.uri ? (
                  <Tag href={breadcrumb.uri} key={breadcrumbs.uri}>
                    {breadcrumb.title}
                  </Tag>
                ) : (
                  <span key={breadcrumbs.title}>{breadcrumb.title}</span>
                );
              })}
            </Breadcrumbs>
          )}
          <h1>{title}</h1>
          <p>{intro}</p>
          <Button onClick={() => onFavoriteClick?.()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="18"
              viewBox="0 0 23 18"
              fill="none"
            >
              <g>
                <path
                  d="M11.2847 5.94242C11.2847 3.02378 8.92203 0.800049 6.14237 0.800049C3.36271 0.800049 1 3.16276 1 5.94242C1 7.05429 1.34274 8.13361 1.97288 9.00005C3.08475 10.5289 6.28136 13.1695 11.2847 17.2C16.4271 13.0306 19.7627 10.2509 20.5966 9.00005C21.1525 8.16615 21.5695 7.05429 21.5695 5.94242C21.5695 3.02378 19.2068 0.800049 16.4271 0.800049C13.6475 0.800049 11.2847 3.16276 11.2847 5.94242Z"
                  stroke="#333333"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            Lagre i favoritter
          </Button>
        </div>
      </section>

      {!!image && (
        <section className="article__content">
          <img src={image.src} alt={image.alt} className="article__image" />
        </section>
      )}
      <section className="article__content">
        {!!summary && <Summary summary={summary} />}
        {content ? (
          <div
            className="article__body"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : (
          <div className="article__body">{children}</div>
        )}
        {!!showMore && (
          <More>
            {showMore.map((link) => {
              const Tag = link.linker ?? 'a';
              return (
                <Tag href={link.uri} key={link.uri}>
                  {link.title}
                </Tag>
              );
            })}
          </More>
        )}
        {!!lastUpdated && (
          <div className="article__updated">
            Sist oppdatert:{' '}
            {new Date(lastUpdated).toLocaleDateString('nb-NO', {
              dateStyle: 'long',
            })}
          </div>
        )}
      </section>
    </article>
  );
}

Article.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      uri: PropTypes.string,
      linker: PropTypes.func,
    })
  ),
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  summary: PropTypes.string,
  content: PropTypes.string,
  showMore: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      linker: PropTypes.func,
    })
  ),
  lastUpdated: PropTypes.string,
  onFavoriteClick: PropTypes.func,
  children: PropTypes.node,
};

export default Article;
